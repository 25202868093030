import { Box } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { KeyboardBackspaceRounded } from '@mui/icons-material';
import { resetState } from '../redux/form';
import classnames from 'classnames';

export const RestartFlowSnackbar = () => {
    const dispatch = useDispatch();
    const onClick = () => {
        dispatch(resetState());
    };
    return (
        <Box className={classnames('custom-snackbar-container', 'server-error')} onClick={onClick}>
            <span>Spre ecranul de început</span>
            <KeyboardBackspaceRounded />
        </Box>
    );
};

const InnerSnackbarUtilsConfigurator = (props) => {
    props.setUseSnackbarRef(useSnackbar());
    return null;
};

let useSnackbarRef;
const setUseSnackbarRef = (useSnackbarRefProp) => {
    useSnackbarRef = useSnackbarRefProp;
};

export const SnackbarUtilsConfigurator = () => {
    return <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />;
};

const SnackbarService = {
    toast(msg, closeOnClick = false, options = { variant: 'default' }) {
        let opt = {
            ...options,
        };
        if (closeOnClick) {
            opt = {
                ...opt,
                onClick: () => useSnackbarRef.closeSnackbar(key),
            };
        }
        const key = useSnackbarRef.enqueueSnackbar(msg, opt);
    },
};

export default SnackbarService;
