import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axiosInstance, { errorHandler } from '../helpers/axios';
import { triggerModal } from '../redux/infoModal';
import { APP_CONFIG } from '../helpers/config';
import { setStepId } from '../redux/form';

const initialState = {
    actions: [],
    token: {},
    initialized: false
};

const mockApi = false;

export const startSession = createAsyncThunk('/start-session', async (values, { dispatch, rejectWithValue }) => {
    try {
        const response = await axiosInstance.post('/start-session', {
            query: values
        });

        dispatch(setStepId(APP_CONFIG.steps.WELCOME_REGISTRATION));

        return response.data.token;
    } catch (e) {
        return errorHandler(e, rejectWithValue);
    }
});

export const healthCheck = createAsyncThunk('/health-check', async (values, { dispatch, getState, rejectWithValue }) => {
    try {
        const { auth } = getState();

        const headers = {
            Authorization: `Bearer ${auth.token}`,
        };

        if (mockApi) { // dev flow
            return true;
        }

        const session = await axiosInstance.get('/health-check', { headers });
        
        console.log({ session });

        return true;
    } catch (e) {
        console.log({ e })

        dispatch(triggerModal());

        return errorHandler(e, rejectWithValue);
    }
});


const auth = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetState: () => initialState,
        initHealthCheck: (state) => {
            state.initialized = true;
        },
        resetHealthCheck: (state) => {
            state.initialized = false;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(startSession.pending, (state) => {
                return state;
            })
            .addCase(startSession.fulfilled, (state, { payload }) => {
                state.token = payload;
            })
            .addCase(startSession.rejected, (state) => {
                state.posInfo = null;
            })
    },
});

export const { resetState, initHealthCheck, resetHealthCheck } = auth.actions;

export default auth.reducer;
