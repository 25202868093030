import { Box } from '@mui/system';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import NotFound from '../../containers/NotFound';
import { CheckEmail } from '../../components/CheckEmail';
import { RegisterLAS } from '../../components/RegisterLAS';
import { ActivateAccount } from '../../components/ActivateAccount';
import { ProductSelection } from '../../components/ProductSelection';
import { WelcomeRegistration } from '../../components/WelcomeRegistration';
import { GetStarted } from '../../components/GetStarted';

import { APP_CONFIG } from '../../helpers/config';

import styles from './Steps.module.scss';
import { UnderMaintenance } from '../../components/UnderMaintenance';
import { CheckVideo } from '../../components/CheckVideo';

const renderStep = (stepId) => {
    // return <UnderMaintenance />;
    const {
        GET_STARTED,
        WELCOME_REGISTRATION,
        CHECK_EMAIL,
        CHECK_VIDEO,
        REGISTER_LAS,
        ACTIVATE_ACCOUNT,
        NOT_FOUND,
        PRODUCT_SELECTION,
        UNDER_MAINTENANCE,
    } = APP_CONFIG.steps;
    switch (stepId) {
        case GET_STARTED: {
            return <GetStarted />;
        }
        case WELCOME_REGISTRATION: {
            return <WelcomeRegistration />;
        }
        case PRODUCT_SELECTION: {
            return <ProductSelection />;
        }
        case CHECK_VIDEO: {
            return <CheckVideo />;
        }
        case CHECK_EMAIL: {
            return <CheckEmail />;
        }
        case REGISTER_LAS: {
            return <RegisterLAS />;
        }
        case NOT_FOUND: {
            return <NotFound />;
        }
        case ACTIVATE_ACCOUNT: {
            return <ActivateAccount />;
        }
        case UNDER_MAINTENANCE: {
            return <UnderMaintenance />;
        }
        default: {
            return <></>;
        }
    }
};

const Steps = () => {
    const [step, setStep] = useState(null);

    const { stepId } = useSelector((state) => state.form);

    useEffect(() => {
        setStep(renderStep(stepId));
    }, [stepId]);

    return <Box className={styles.container}>{step}</Box>;
};

export default Steps;
