/* eslint-disable */
import * as yup from 'yup';
import { Box } from '@mui/system';
import classnames from 'classnames';
import { LoadingButton } from '@mui/lab';
import { useState, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, Typography, Fade } from '@mui/material';
import { useForm, Controller, useFormState } from 'react-hook-form';

import { checkEmail } from '../../redux/form';
import { logEvent } from '../../redux/logger';
import { APP_CONFIG } from '../../helpers/config';

import styles from './CheckEmail.module.scss';
import { formatPayloadObject } from '../../helpers/general';

const isDev = process.env.NODE_ENV === 'development';

const schema = yup
    .object({
        email: yup
            .string()
            .trim()
            .email(APP_CONFIG.validationMessages.invalidEmailFormat)
            .max(APP_CONFIG.maxCharacters, APP_CONFIG.validationMessages.max())
            .test('blacklist-emails', APP_CONFIG.validationMessages.invalidEmailFormat, function (value) {
                if (isDev) {
                    return true;
                }
                return APP_CONFIG.regexBlacklistEmails.test(value);
            })
            .required(APP_CONFIG.validationMessages.required),
        confirm_email: yup
            .string()
            .trim()
            .email(APP_CONFIG.validationMessages.invalidEmailFormat)
            .max(APP_CONFIG.maxCharacters, APP_CONFIG.validationMessages.max())
            .required(APP_CONFIG.validationMessages.required)
            .test('emailConfirmation', APP_CONFIG.validationMessages.emailDontMatch, function (value, att, altceva) {
                return value !== undefined ? value.toLowerCase() === this.parent.email.toLowerCase() : false;
            }),
    })
    .required();

export const CheckEmail = () => {
    const dispatch = useDispatch();

    const { checkEmailResult } = useSelector((state) => state.form);

    const [loading, setLoading] = useState(false);

    const {
        control,
        handleSubmit,
        setError,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const { dirtyFields } = useFormState({
        control,
    });

    useEffect(() => {
        const event = {
            action: 'navigate',
            page: APP_CONFIG.steps.CHECK_EMAIL,
            payload: [],
            errors: [],
        };

        dispatch(logEvent(event));
    }, []);

    useEffect(() => {
        if (errors.email || errors.confirm_email) {
            const event = {
                action: 'error',
                page: APP_CONFIG.steps.CHECK_EMAIL,
                payload: [],
                errors: formatPayloadObject(errors)
            };

            dispatch(logEvent(event));
        }
    }, [errors]);

    useEffect(() => {
        if (checkEmailResult.email) {
            setValue('email', checkEmailResult.email, { shouldDirty: true });
        }
    }, [checkEmailResult, setValue]);

    const onFocusAction = (eventObj) => {
        const input = eventObj.target.name;
        const event = {
            action: 'focus',
            page: APP_CONFIG.steps.REGISTER_LAS,
            payload: formatPayloadObject({ input }),
            errors: [],
        };

        dispatch(logEvent(event));
    };

    const onSubmit = (data) => {
        setLoading(true);

        dispatch(checkEmail(data)).then((response) => {
            if (response?.payload.status === 422) {
                setLoading(false);
                setError('email', {
                    type: 'api_error',
                    message: response.payload.data?.errors?.email?.[0],
                });
                return;
            }
        });
    };

    const handleChange = (e) => {
        e.preventDefault();
    };

    return (
        <Fade in>
            <Box className={classnames('container', styles.customContainerMobile)}>
                <Box className={styles.copy}>
                    <Typography variant='body'>
                        Pentru înregistrarea dispozitivului introduceți adresa de email mai jos:
                    </Typography>
                </Box>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Box className={styles.email}>
                        <Controller
                            name='email'
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    required
                                    onFocusCapture={onFocusAction}
                                    error={!!errors.email}
                                    helperText={errors?.email?.message}
                                    id='outlined-error'
                                    label='Email'
                                    fullWidth
                                    onCut={handleChange}
                                    onCopy={handleChange}
                                    onPaste={handleChange}
                                    onSelect={handleChange}
                                />
                            )}
                            defaultValue={''}
                        />
                    </Box>

                    <Box className={styles.email}>
                        <Controller
                            name='confirm_email'
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    required
                                    onFocusCapture={onFocusAction}
                                    error={!!errors.confirm_email}
                                    helperText={errors?.confirm_email?.message}
                                    id='outlined-error'
                                    label='Confirmare email'
                                    fullWidth
                                    onCut={handleChange}
                                    onCopy={handleChange}
                                    onPaste={handleChange}
                                />
                            )}
                            defaultValue={''}
                        />
                    </Box>

                    <Box className={styles.nextButton}>
                        <LoadingButton
                            variant='contained'
                            type='submit'
                            loading={loading}
                            disabled={Object.keys(dirtyFields).length !== 2}
                        >
                            Pasul următor
                        </LoadingButton>
                    </Box>
                </form>
            </Box>
        </Fade>
    );
};
/* eslint-enable */
