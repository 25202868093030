import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import axiosInstance, { errorHandler } from '../helpers/axios';

const initialState = {
    actions: [],
    user: {},
};

const mockApi = true;

export const logEvent = createAsyncThunk('/log', async (values, { dispatch, getState, rejectWithValue }) => {
    try {
        const { auth, form } = getState();
        const headers = {
            Authorization: `Bearer ${auth.token}`,
        };

        const payload = { ...values, is_back_action: form.navBack };

        const response = await axiosInstance.post('/action', payload, { headers });

        console.log({ response });

        return payload;
    } catch (e) {
        return errorHandler(e, rejectWithValue);
    }
});

const logger = createSlice({
    name: 'logger',
    initialState,
    reducers: {
        resetState: () => initialState,
    },
    extraReducers: (builder) => {
        builder
            .addCase(logEvent.pending, (state) => {
                return state;
            })
            .addCase(logEvent.fulfilled, (state, { payload }) => {
                console.log({ payload });
                return state;
            })
            .addCase(logEvent.rejected, (state) => {
                //try again to log?
                return state;
            });
    },
});

export const { resetState } = logger.actions;

export default logger.reducer;
