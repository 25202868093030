import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';

import { APP_CONFIG, DEFAULT_IQOS_TYPE, STARTING_STEP } from '../helpers/config';
import axiosInstance, { errorHandler } from '../helpers/axios';

const initialState = {
    stepId: STARTING_STEP,
    stepHistory: [],
    uniqueCode: '',
    loading: false,
    checkEmailResult: {
        email: '',
        status: '',
    },
    loginResult: {},
    registerLASResult: {},
    qrCode: true,
    productType: '',
    iqosType: DEFAULT_IQOS_TYPE,
    posInfo: null,
    navBack: true,
};

export const checkEmail = createAsyncThunk('/users/search', async (values, { rejectWithValue }) => {
    try {
        //mock user data response
        const mockResponse = {
            status: 'not_found',
            email: values.email,
            pending_lead_data: {
                id: 1106,
                telcor_id: 'VcZ13Lf4',
                dcs_id: null,
                qr_code: false,
                email: values.email,
                phone_number: '0754544544',
                first_name: null,
                last_name: null,
                product_type: 'iqos',
                marketing_opt_in: 0,
                pos_code: '99AA4',
                device_code: null,
                accessory_code: null,
                not_eligible: 0,
                ip: null,
                third_party_delivered: 0,
                deleted_at: null,
                created_at: '2023-04-02T07:29:42.000000Z',
                updated_at: '2023-04-02T07:32:48.000000Z',
            },
        };

        return mockResponse;
    } catch (e) {
        return errorHandler(e, rejectWithValue);
    }
});

export const registerLAS = createAsyncThunk('/users/register-las', async (values, { rejectWithValue }) => {
    try {
        const mockResponse = {
            dcs_id: '12345',
        };

        return mockResponse;
    } catch (e) {
        return errorHandler(e, rejectWithValue);
    }
});

export const downloadRegulation = createAsyncThunk(
    '/users/download/organic-sales-regulation',
    async (values, { rejectWithValue }) => {
        try {
            window.open('/regulament-campanie.pdf', '_blank');
        } catch (e) {
            return errorHandler(e, rejectWithValue);
        }
    },
);

export const checkPosCode = createAsyncThunk('/pos/{cod}', async (values, { rejectWithValue }) => {
    try {
        console.log({ values });

        const mockResponse = {
            parent: null,
            county: 'ARAD',
            city: 'ARAD',
            isms_code: '0000061',
            denumire: 'Test SRL',
        };

        return mockResponse;
    } catch (e) {
        return errorHandler(e, rejectWithValue);
    }
});

const form = createSlice({
    name: 'form',
    initialState,
    reducers: {
        resetState: () => initialState,
        setStepId: (state, { payload }) => {
            state.stepHistory.push(current(state).stepId);
            state.stepId = payload;
            state.navBack = false;
        },
        goBack: (state) => {
            const currentHistory = [...current(state).stepHistory];
            state.stepId = currentHistory.slice(-1)[0];
            state.stepId = currentHistory.slice(-1)[0];
            state.navBack = true;
            state.stepHistory = currentHistory.slice(0, currentHistory.length - 1);
        },
        setUniqueCode: (state, { payload }) => {
            state.uniqueCode = payload;
        },
        setQrCode: (state) => {
            state.qrCode = true;
        },
        setProductType: (state, { payload }) => {
            state.productType = payload;
            state.stepHistory.push(current(state).stepId);
            state.stepId = APP_CONFIG.steps.CHECK_VIDEO;
        },
        setStoreIqosType: (state, { payload }) => {
            state.iqosType = payload;
        },
        setPosInfo: (state, { payload }) => {
            state.posInfo = payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(checkEmail.fulfilled, (state, { payload }) => {
                state.checkEmailResult = payload;
                state.stepHistory.push(current(state).stepId);

                state.stepId = APP_CONFIG.steps.REGISTER_LAS;
                return;
            })
            .addCase(registerLAS.fulfilled, (state, { payload }) => {
                state.registerLASResult = payload;
                state.stepHistory.push(current(state).stepId);
                state.stepId = APP_CONFIG.steps.ACTIVATE_ACCOUNT;
            })
            .addCase(checkPosCode.fulfilled, (state, { payload }) => {
                state.posInfo = payload;
            })
            .addCase(checkPosCode.rejected, (state) => {
                state.posInfo = null;
            });
    },
});

export const { resetState, setStepId, goBack, setUniqueCode, setQrCode, setProductType, setStoreIqosType, setPosInfo } =
    form.actions;

export default form.reducer;
