import classnames from 'classnames';
import { Box, Link, Fade, Typography } from '@mui/material';

import styles from './UnderMaintenance.module.scss';

export const UnderMaintenance = () => {
    return (
        <Fade in>
            <Box className={classnames('container', styles.customContainerMobile)}>
                <Typography sx={{ mb: 2, textAlign: 'center' }} variant='h2'>
                    Ne pare rău, dar momentan înregistrarea online nu este disponibilă.
                </Typography>
                <Typography variant='body' sx={{ mb: 2 }}>
                    Te rugăm trimite prin SMS gratuit* la{' '}
                    <a className={styles.sms} href='sms:3768'>
                        3768
                    </a>{' '}
                    codul unic al magazinului înscris pe kit-ul achiziționat, iar un consultant te va contacta în 48 de
                    ore pentru înregistrarea dispozitivului și pentru a beneficia de pachete de rezerve gratuite
                    sau de un discount în contul tău IQOS.
                </Typography>
                <Typography variant='body' sx={{ mb: 2 }}>
                    Oferta menționată mai sus va putea fi accesată doar prin înregistrarea cu ajutorul consultantului
                    nostru.
                </Typography>
                <Typography sx={{ mb: 2 }} variant='small'>
                    *toate rețelele de telefonie mobilă, mai puțin RDS
                </Typography>
            </Box>
        </Fade>
    );
};
