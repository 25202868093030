import axios from 'axios';
import { setStepId } from '../redux/form';
import {
    APP_CONFIG,
    QR_CODE_REQUIRED_ENDPOINTS,
    PRODUCT_TYPE_REQUIRED_ENDPOINTS,
} from './config';
import SnackbarService, { RestartFlowSnackbar } from './snackbar';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

export const setInterceptor = (store, instance) => {
    instance.interceptors.request.use(function (config) {
        const state = store.getState();

        if (state?.form?.qrCode) {
            if (QR_CODE_REQUIRED_ENDPOINTS.includes(config.url)) {
                if (config.method === 'get') {
                    config.params = {
                        ...config.params,
                        qr_code: state?.form?.qrCode,
                    };
                }

                if (config.method === 'post') {
                    config.data = {
                        ...(config.data || {}),
                        qr_code: state?.form?.qrCode,
                    };
                }
            }
        }

        if (state?.form?.productType) {
            if (PRODUCT_TYPE_REQUIRED_ENDPOINTS.includes(config.url)) {
                if (config.method === 'get') {
                    config.params = {
                        ...config.params,
                        product_type: state?.form.productType,
                    };
                }

                if (config.method === 'post') {
                    config.data = {
                        ...(config.data || {}),
                        product_type: state?.form.productType,
                    };
                }
            }
        }

        return config;
    });

    instance.interceptors.response.use(undefined, function (error) {
        switch (error?.response?.status) {
            case 422: {
                break;
            }
            case 404: {
                store.dispatch(setStepId(APP_CONFIG.steps.NOT_FOUND));
                break;
            }
            default: {
                SnackbarService.toast('S-a întâmplat ceva, încearcă din nou.', true, {
                    action: () => <RestartFlowSnackbar />,
                    preventDuplicate: true,
                });
            }
        }
        return Promise.reject(error);
    });
};

export const errorHandler = (e, rejectWithValue) => {
    const rejectObj = {
        data: e?.response?.data || {},
        status: e?.response?.status || 999,
    };
    return rejectWithValue(rejectObj);
};

export default axiosInstance;
