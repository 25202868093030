import { Box } from '@mui/system';
import { Fade } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';

import { goBack } from '../../redux/form';

import styles from './GoBack.module.scss';
import { GO_BACK_FORBIDDEN_STEPS } from '../../helpers/config';

const GoBack = () => {
    const dispatch = useDispatch();

    const { stepHistory, stepId } = useSelector((state) => state.form);

    const handleClick = () => {
        dispatch(goBack());
    };

    if (stepHistory.length === 0 || GO_BACK_FORBIDDEN_STEPS.includes(stepId)) {
        return <Box className={styles.container}></Box>;
    }

    return (
        <Fade in>
            <Box className={styles.container}>
                <Box onClick={handleClick} className={styles.button}>
                    <KeyboardBackspaceRoundedIcon />
                    <span>Înapoi</span>
                </Box>
            </Box>
        </Fade>
    );
};

export default GoBack;
