import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#34303d',
        },
        error: {
            main: '#ff0000',
            light: '#ff0000',
            dark: '#ff0000',
        },
    },
    typography: {
        h1: {
            fontWeight: 'bold',
            fontSize: '25px',
            color: '#000',
            lineHeight: '33px',
            letterSpacing: '0',
        },
        h2: {
            fontWeight: 'normal',
            fontSize: '25px',
            color: '#000',
            lineHeight: '33px',
            letterSpacing: '0',
        },
        h2bold: {
            fontWeight: 'bold',
            fontSize: '25px',
            color: '#000',
            lineHeight: '33px',
            letterSpacing: '0',
        },
        body: {
            lineHeight: '21px',
            letterSpacing: '0',
        },
        small: {
            fontSize: '13px',
            lineHeight: '18px',
            letterSpacing: '0',
        },
        small2: {
            fontSize: '12px',
            lineHeight: '18px',
            letterSpacing: '0',
            fontWeight: 'normal',
            textDecoration: 'underline',
            color: '#000',
            opacity: '0.5',
        },
        subtitle: {
            fontWeight: 'bold',
            fontSize: '16px',
            color: '#000',
            opacity: '0.5',
        },
        bold: {
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '21px',
            letterSpacing: '0',
        },
        boldTitle: {
            fontWeight: 'bold',
        },
        link: {
            color: '#00d1d2',
            fontSize: '16px',
            lineHeight: '21px',
            letterSpacing: '0',
            '&:hover': {
                cursor: 'pointer',
            },
        },
    },
    components: {
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    svg: {
                        width: '20px',
                        height: '20px',
                        fill: '#000',
                    },
                    '&.Mui-checked': {
                        svg: {
                            fill: '#00d1d2',
                        },
                    },
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    padding: '4px',
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    marginLeft: '-7px',
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontWeight: 'normal',
                    fontSize: '16px',
                    lineHeight: '21px',
                    letterSpacing: '0',
                    color: '#000',
                    opacity: '0.5',
                },
            },
        },
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    h1: 'h1',
                    h2: 'h2',
                    h3: 'h3',
                    h4: 'h4',
                    h5: 'h5',
                    h6: 'h6',
                    subtitle: 'h2',
                    body: 'p',
                    small: 'p',
                    small2: 'p',
                    h2bold: 'h2',
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    margin: '10px 0 0 0',
                    fontWeight: '500',
                    fontSize: '12px',
                },
            },
        },
        MuiInputBase: {
            defaultProps: {
                autoComplete: 'off',
                required: false, // to override that "beautiful" base HTML required notification
            },
        },
        MuiTextField: {
            defaultProps: {
                inputProps: {
                    autoComplete: 'off',
                    required: false, // to override that "beautiful" base HTML required notification
                },
            },
            styleOverrides: {
                root: {
                    input: {
                        padding: '16px',
                        letterSpacing: '0',
                        lineHeight: '21px',
                        fontSize: '16px',
                        height: 'initial',
                    },
                    label: {
                        fontWeight: 'normal',
                        fontSize: '16px',
                        color: '#000',
                        opacity: '0.5',
                        letterSpacing: '0',
                        lineHeight: '21px',
                    },
                    fieldset: {
                        border: '1px solid #000', //fixme: try to avoid using !important
                    },
                },
            },
        },
        MuiLink: {
            defaultProps: {
                color: '#00d1d2',
                target: '_blank',
                rel: 'noopener',
            },
        },
        MuiButton: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    borderRadius: '25.5px',
                    fontSize: '16px',
                    fontWeight: 'normal',
                    padding: '14px 34px 16px',
                    letterSpacing: '0',
                    lineHeight: '21px',
                    textTransform: 'initial',
                    cursor: 'pointer',
                    appearance: 'none',
                    display: 'inline-block',
                    outline: 'none',
                    boxShadow: 'inset 0 0 0 2px var(--box-shadow-color)',
                    textAlign: 'center',
                    textDecoration: 'none',
                    transition: 'background 0.2s, box-shadow 0.3s ease-out, color 0.2s',
                    userSelect: 'none',
                    verticalAlign: 'middle',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    position: 'relative',
                    zIndex: '1',
                    border: 'none',
                    willChange: 'background, bos-shadow, color',
                    '&:hover': {
                        color: '#34303d',
                        opacity: '1 ',
                    },
                    '&:hover:after': {
                        transform: 'translate(-10%, -50%) skew(-30deg)',
                        opacity: 1,
                    },
                    '&:after': {
                        // prettier-ignore
                        content: '" "',
                        position: 'absolute',
                        top: '50%',
                        left: 0,
                        background: '#00D1D2',
                        width: '120%',
                        height: '120%',
                        zIndex: '-1',
                        transition: 'transform .25s ease-out',
                        transform: 'translate(-101%, -50%) skew(0deg)',
                        opacity: 1,
                    },
                    '&:disabled': {
                        background: '#787878',
                        color: 'rgba(255, 255, 255, 0.5)',
                    },
                },
            },
        },
    },
});
