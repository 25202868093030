import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import Steps from '../Steps';
import GoBack from '../../components/GoBack';



import './App.module.scss';
import InfoModal from '../../components/InfoModal';
import { healthCheck } from '../../redux/auth';

let healthCheckInterval = null;

function App() {
    const dispatch = useDispatch();
    const healthCheckInitialized = useSelector((state) => state.auth.initialized);
    const state = useSelector((state) => state);

    useEffect(() => {
        if (healthCheckInitialized) {
            healthCheckInterval = setInterval(() => {
                dispatch(healthCheck());
                console.log('Health check!');
            }, 1000 * 5); //five seconds check?
        }

        if (!healthCheckInitialized && healthCheckInterval) {
            console.log('Health check interval cleared!');

            clearInterval(healthCheckInterval);
        }
    }, [healthCheckInitialized]);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <GoBack />
            <Steps />
            <InfoModal />
        </LocalizationProvider>
    );
}

export default App;
