import React from 'react';
import classnames from 'classnames';
import { Box, Button, Fade, Typography, Link } from '@mui/material';

import SnackbarService from '../../helpers/snackbar';
import { handleAmountOfReward } from '../../helpers/general';

import styles from './ActivateAccount.module.scss';
import { useSelector } from 'react-redux';

export const ActivateAccount = () => {
    const resendEmail = () => {
        SnackbarService.toast('S-a retrimis un email de activare');
    };

    const { registerLASResult } = useSelector((state) => state.form);

    return (
        <Fade in>
           <Box className={classnames('container', styles.customContainerMobile)}>
                <Box className={styles.copy}>
                    <Typography variant='body'>
                        Mai ai doar un pas! Verifică-ți e-mail-ul, urmează instrucțiunile primite și activează-ți contul
                        pe <Link href='https://www.iqos.ro'>www.iqos.ro</Link>. După activare, vei primi un cod pentru {handleAmountOfReward(registerLASResult.sku_model)} pachete gratuite.
                    </Typography>
                    <Typography variant='small'>
                        *beneficiul va fi confirmat de catre noi printr-un SMS in termen de maxim 24h
                    </Typography>
                </Box>

                <Button variant='contained' onClick={resendEmail} fullWidth>
                    Retrimite emailul de activare
                </Button>
            </Box>
        </Fade>
    );
};
