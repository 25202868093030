import { useState } from 'react';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { FormHelperText, Typography, Dialog, DialogTitle, DialogContent, IconButton, Box } from '@mui/material';

import ShopCode from '../../assets/images/shop-code.jpg';
import DeviceCode from '../../assets/images/device-code.jpg';
import DeviceCodeLil from '../../assets/images/device-code-lil.png';
import { ReactComponent as InfoSvg } from '../../assets/images/info.svg';
import AccessoryCodeLil from '../../assets/images/accessory-code-lil-solid.png';
import AccessoryCodeIqosDuo from '../../assets/images/accessory-code-iqos-duo.png';

import { APP_CONFIG, IQOS_TYPES, PRODUCT_TYPES } from '../../helpers/config';

import styles from './FormHelperDevice.module.scss';

export const FormHelperDevice = ({ id, copy, type }) => {
    const [opened, setOpened] = useState(false);
    const { productType, iqosType } = useSelector((state) => state.form);
    let img;
    switch (type) {
        case APP_CONFIG.deviceCodeId: {
            img = productType === PRODUCT_TYPES.iqos ? DeviceCode : DeviceCodeLil;
            break;
        }
        case APP_CONFIG.shopCodeId: {
            img = ShopCode;
            break;
        }
        case APP_CONFIG.accessoryId: {
            if (productType === PRODUCT_TYPES.iqos) {
                if(iqosType === IQOS_TYPES[0].value) {
                  img = AccessoryCodeIqosDuo;
                } else if(iqosType === IQOS_TYPES[1].value) {
                  // to-do: replace img
                  img = AccessoryCodeIqosDuo;
                } else {
                  // to-do: replace img
                  img = AccessoryCodeIqosDuo;
                }
            } else {
                img = AccessoryCodeLil;
            }
            break;
        }
        default: {
            img = DeviceCode;
            break;
        }
    }

    return (
        <>
            <FormHelperText id={id} className={styles.formItemHelper} onClick={() => setOpened(true)}>
                <InfoSvg />
                <Typography variant='small2' component={'small'}>
                    {copy}
                </Typography>
            </FormHelperText>
            <Dialog open={opened} className={styles.modalBody}>
                <DialogTitle>
                    <IconButton
                        aria-label='close'
                        onClick={() => setOpened(false)}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: '#000',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ mt: 2 }}>
                        <img src={img} alt='explicatie-cod' />
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};
