import { combineReducers, configureStore } from '@reduxjs/toolkit';

import auth from './auth';
import form from './form';
import logger from './logger';
import infoModal from './infoModal';

const reducers = combineReducers({
    auth: auth,
    form: form,
    infoModal: infoModal,
    logger: logger
});

export const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([]),
    enhancers: [],
});
