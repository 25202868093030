import React from 'react';
import { Box, Modal, Typography, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import styles from './InfoModal.module.scss';
import { resetModal } from '../../redux/infoModal';
import { resetHealthCheck } from '../../redux/auth';
import { APP_CONFIG } from '../../helpers/config';

const { GET_STARTED } = APP_CONFIG.steps;

export default function InfoModal() {
    const dispatch = useDispatch();

    const { open } = useSelector((state) => state.infoModal);

    const handleClose = () => {
        //redirect user
        dispatch(resetHealthCheck());
        dispatch(resetModal(GET_STARTED));
    };

    return (
        <Modal open={open} onClose={() => {}} aria-labelledby='modal-title' aria-describedby='modal-description'>
            <Box className={styles.container}>
                <Typography className={styles.title} component='h2' variant='h4'>
                    Sesiune expirata
                </Typography>
                <Typography className={styles.description} component='p' variant='subtitle1'>
                    Sesiunea ta a expirat!
                </Typography>
                <Button className={styles.button} variant='contained' onClick={handleClose}>
                    Începe din nou
                </Button>
            </Box>
        </Modal>
    );
}
