import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setStepId } from './form';
import axiosInstance, { errorHandler } from '../helpers/axios';
import SnackbarService from '../helpers/snackbar';
import { resetHealthCheck } from './auth';
import { resetState } from './form';

const initialState = {
    open: false,
};

export const resetModal = createAsyncThunk('/reset-modal', async (values, { dispatch, rejectWithValue }) => {
    try {
        dispatch(setStepId(values));
        dispatch(resetHealthCheck());
        dispatch(resetState());
    
        return values;
    } catch (e) {
        SnackbarService.toast(e);
        return errorHandler(e, rejectWithValue);
    }
});

const infoModal = createSlice({
    name: 'infoModal',
    initialState,
    reducers: {
        triggerModal: (state, action) => {
            state.open = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(resetModal.fulfilled, (state) => {
            state.open = false;

            return state;
        });
    },
});

export const { triggerModal } = infoModal.actions;

export default infoModal.reducer;
