import { useEffect } from 'react';

import { Box } from '@mui/system';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Typography, Fade, Link } from '@mui/material';

import { APP_CONFIG } from '../../helpers/config';
import { downloadRegulation, setStepId } from '../../redux/form';

import styles from './WelcomeRegistration.module.scss';
import { LoadingButton } from '@mui/lab';
import { logEvent } from '../../redux/logger';
import { initHealthCheck } from '../../redux/auth';

const footerList = [
    '*www.iqos.ro/termeni-si-conditii-iqos-care-plus',
    '**www.iqos.ro/termeni-si-conditii-iqos-care-plus',
    '***Valabilă la primul dispozitiv și condiționată de activarea contului și acceptarea acordului de marketing',
];

export const WelcomeRegistration = () => {
    const dispatch = useDispatch();
    const navigation = useHistory();
    const token = useSelector((state) => state.auth.token);

    const { unique_code } = useParams();

    const onClick = () => {
        dispatch(setStepId(APP_CONFIG.steps.PRODUCT_SELECTION));
    };

    const downloadFile = () => {
        dispatch(downloadRegulation());
    };

    useEffect(() => {
        const event = {
            type: 'navigate',
            page: window.location.href,
            step: APP_CONFIG.steps.WELCOME_REGISTRATION,
            payload: { unique_code },
        };

        dispatch(logEvent(event));
    }, []);

    return (
        <Fade in>
            <Box className={styles.wrapper}>
                <Box className={styles.header}>
                    <Typography variant='h2'>Bine ai venit!</Typography>
                </Box>
                <Box className={classnames('container', styles.container)}>
                    <Typography variant='h3'>Înregistrează-ți noul dispozitiv și primești gratuit:</Typography>
                    <Box className={styles.list}>
                        <ul>
                            <li>
                                <Typography variant='body'>Pachete de rezerve din tutun*</Typography>
                            </li>
                            <li>
                                <Typography variant='body'>Garanție Care Plus** pentru dispozitivul IQOS/lil</Typography>
                            </li>
                            <li>
                                <Typography variant='body'>
                                    Consultanță personală de la reprezentantul IQOS/lil***
                                </Typography>
                            </li>
                            <li>
                                <Typography variant='body'>
                                    IQOS Club, programul de loialitate cu surprize si concursuri
                                </Typography>
                            </li>
                        </ul>
                    </Box>
                </Box>
                <Box className={styles.center}>
                    <Typography variant='body' sx={{ mt: 3 }}>
                        <Link component='button' sx={{ fontSize: 16 }} onClick={downloadFile}>
                            Vezi regulamentul campaniei
                        </Link>
                    </Typography>
                </Box>
                <Box className={`${styles.list} ${styles.footerList}`}>
                    <ul>
                        {footerList.map((item, idx) => (
                            <li key={`welcome-footer-list--${idx}`}>
                                <Typography variant='body'>{item}</Typography>
                            </li>
                        ))}
                    </ul>
                </Box>
                <Box className={styles.nextButton}>
                    <LoadingButton sx={{ width: '100%' }} variant='contained' type='submit' onClick={onClick}>
                        Pasul următor
                    </LoadingButton>
                </Box>
            </Box>
        </Fade>
    );
};
