import classnames from 'classnames';
import { Box, Typography, Fade } from '@mui/material';


import styles from './NotFound.module.scss';

const NotFound = () => {

    return (
        <Fade in>
            <Box className={classnames('container', styles.customContainerMobile, styles.noParent)}>
             
                <Box className={styles.description}>
                    <Typography variant='body'>
                        Ne pare rău, această pagină nu este disponibilă. Dorești să îți înregistrezi KIT-ul achiziționat
                        într-una din locațiile noastre partenere? Trimite SMS* la{' '}
                        <a className={styles.sms} href='sms:3768'>
                            3768
                        </a>{' '}
                        cu codul magazinului din care ai achiziționat și urmează instrucțiunile.
                    </Typography>
                    <Typography variant='small' sx={{ mt: 1 }}>
                        *SMS-ul este gratuit în toate rețelele de telefonie mobilă, cu excepția RCS & RDS unde costul se
                        aplică conform planului tarifar aferent rețelei.
                    </Typography>
                </Box>
            </Box>
        </Fade>
    );
};
export default NotFound;
