import classnames from 'classnames';
import { Box, Fade, Typography, Button } from '@mui/material';
import { useDispatch } from 'react-redux';

import styles from './GetStarted.module.scss';
import { startSession, initHealthCheck } from '../../redux/auth';
import { useLocation } from 'react-router-dom';

const mockToken = "9babe2opX3DYHYHrXDhIhEkHZj5M7Sn917lunVjg3hQy2sSxBFGOpdMlraJP4tT56Gm4pyDF/2zxqVWSgn58Y6DYTzI0zXkRcQ9LEDUh6/asz0pX8QU55So3I39TgTozAgzmN3lmAgy39zS2FwhSfNw3GbR+zZLiMhFgY+6DWNlV12bCC2J0U77pZ/DGO40EzxXtADfFxmbmuMmZljyn3Ha3+amHlbMXedjMtSg2gf5eb7KiWimSwM4ESyLEcNe/Wq0oKA8/mUzPvhtGwiC+LlroR4xIpt0+rVTw7ji5iAZ+7b055iunrL3WrSXp9ZtuhUeYc3PGI6OUZt6+C8qYlLOR2VXPhH7OkblVlExzPoy/k9Yq4SO9++c1h8fTi5kvoTAVeqePmI331ah0PtJX3Vao+ZIlNviLyOcj8G2C1NpZDmBWzm4vGRWbIPsw5IoFftxxrijmvLKLCxyVooXnKeZTL1vl7CyRyK0nSInR5TU2ck2XXK+UhAOxNvs26S68lDGFq6aWqAhbIgKsKuOdknrpfMjJlvvIQI9UbGtWKlW2/zj+GisNzW00JF00WVdaczbj4CryvFdMBCQBo5xxOB2N/traK2FaE251HNO6d8YpzhrCS/mw1ygYOUzU9aG3uYC1lDLSYaRhDlPSCvehfglzrie3lMau7Ypxv2jJBkpA8K6la+eqoiyJh8XQ9Npf2Da0TE0iAT48L/+F+ir4Gn1715DyNQE9L4pFpx9gXfRjBHhTrTVtzFpRdaXfKHaziAUWb8/E+qLzG6JNXEWoTM4dcjAhUOJj1xk4vFiQT73E6vexjX3xbmrVAlCpW+2y0ETehizQgY0uehF0VbjEvXJGgEPwqOPj3LKu+270FJtCcYf3LWmkzF80wFMOvwnxwl0e1f1q8LgCZOSYBHOt2C1390IJUWpe8a+GPWFln7Y1JXYkMX4zJVx7hFgQlJOFanbbQ1tMpaMF9Z+1yWSwAyRt2bBXYIQtwk1a+stm5JnhRaI6pWzSl3MHhpMB0KG4p+uK2ZhIs/VhF1qCyM+2LHkba2x86DyB5GuYRZ9NDQq4F4O7HqU9o02c+9jWLhDEQew199vc20fEqO0hXk3qBoKYfbS56jtxHpoRJx8ywrfTLysF8xxGffFp8shQcG/bQTWHGQfq6UVCiK28YCwQkpjSpDOZZMNkBuzZSijUeG+WowsKPQbwY5kOo+0OUJ8lo1p+61lvX6XRuiqfxw2iwk41PwtpE6cMeXZYWbRxievuWFdXstSVYOajsGlY/52EMVpVwGSNijdWDRtphcegD81M9G1UIeAlwHb/GP7s4MJNOjBkOJW3/Xnh4g4mjDhqEAUrNS1zLSsEnLsL5jO/YE3ulgVSAUSZtpxhZqn3eFJLqUFkFWlg3E106S+TRpwNBSpglPO0FwRt6Dquvj0TIOSFNi1P3zjZ1LZBcZcQSDctxRPgMWocAZ7le9tkjMOqM7iIqG7lnPYrfJmx7BeP3p0THS6ONYn77pshn44ZU6lB3KVlBgADdzXjX7NqwHB+IZWXxlCkWgYUR69OXL3/lvONaYHYa6uBPGFSmuhSJAD5toE0zpBd+O1MSbYc4FEm0z+zYPSparhTRoimV99OJuKCkGdaQ9aQG7WI6W7CbPVatOMBQ25FRJpe05YKSMm09RGEwSt/4Q8M0DFvbBNSIpR5FVJIPPoS0qF6caGMB26tXV/7U2iREflPfQ233hGAKeE51KRUpR72Ah7VMVksFiU8Q093fdeKxfGC6OPkNoV6c7yvWgrNtJu7zpHucofbjf+gBJ3GbbI+Sr8OLdnVdg==";

export const GetStarted = () => {
    const dispatch = useDispatch();
    const { search } = useLocation();

    const split = search.split("?query=");

    let token = null;

    if (split[1]) {
        token = decodeURIComponent(split[1])
    }
    
    const startTraining = async () => {
        if (token) {
            dispatch(initHealthCheck());
            dispatch(startSession(token));
        }
    };

    return (
        <Fade in>
            <Box className={classnames('container', styles.customContainerMobile)}>
                {token ? (
                    <>
                        <Typography sx={{ mb: 2, textAlign: 'center' }} variant='h2'>
                            Apasă butonul pentru a începe.
                        </Typography>
                        <Button variant='contained' onClick={startTraining} fullWidth>
                            Start
                        </Button>
                    </>
                ) : (
                    <>
                        <Typography sx={{ mb: 2, textAlign: 'center' }} variant='h2'>
                            Token inexistent!
                        </Typography>
                        <Typography sx={{ mb: 2, textAlign: 'center' }} variant='body1'>
                            Va recomandam sa va intoarceti prin link-ul accesat anterior.
                        </Typography>
                    </>
                )}
            </Box>
        </Fade>
    );
};
